// Convert an api model into an option
export function apiToOption(named) {
    return { label: named.name, value: named.id };
}
const STYLES = {
    option: (styles, { data: _1, isDisabled: _2, isFocused, isSelected: _3 }) => {
        return {
            ...styles,
            backgroundColor: isFocused ? "var(--primary)" : "var(--level-0)",
        };
    },
};
export const SELECT_PROPS = {
    className: "select",
    styles: STYLES,
};
